
export default {
  name: 'EmbeddedHtml',
  props: {
    html: {
      type: Object,
    },
  },
  computed: {
    formattedHtml: function() {
      let _html = '';
      this.$props.html.content.forEach(el => {
        if (el.type !== 'code_block') {
          return;
        }

        _html += this.codeBlock(el);
      });

      return _html;
    },
  },
  methods: {
    codeBlock(element) {
      let _html = '';
      element.content.forEach(code => {
        if (element.attrs.class !== 'language-html') {
          return;
        }

        _html += code.text;
      });

      return _html;
    },
  },
};
